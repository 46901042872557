/**
 * tagName is always uppercase, so the items in the array are also uppercase
 */
export const inputTagNames = ['INPUT', 'TEXTAREA'] as const

/**
 * Check if any of the inputTagNames is the tagName of the current element
 */
export function inputIsActive() {
  return inputTagNames.some((tagName) => document.activeElement?.tagName === tagName)
}
