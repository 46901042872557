import { env } from '@config/index'

export const initMocks = () => {
  if (env.API_MOCKING === 'true') {
    ;(async () => {
      const { worker } = await import('./browser')

      try {
        await worker.start({ onUnhandledRequest: 'bypass' })
      } catch (e) {
        console.warn(e)
      }
    })()
  }
}
