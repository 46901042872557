import { useCallback, useEffect, useState } from 'react'

import { inputIsActive, inputTagNames } from '@utils/inputIsActive'

/**
 * Private utility function, used inside `useInputIsActive` hook.
 *
 * Gets all elements for `inputTagNames` and run the `cb` function on each of them.
 */
function _getEachEventElement(cb: (input: HTMLElement) => void) {
  inputTagNames.forEach((tagName) => {
    document.querySelectorAll<HTMLElement>(tagName).forEach(cb)
  })
}

/**
 * Returns whether or not an input is active.
 *
 * @see inputTagNames for the list of input-like tag names.
 */
export function useInputIsActive(): boolean {
  const [isActive, setIsActive] = useState<boolean>(false)

  const handleChange = useCallback(() => {
    setIsActive(inputIsActive())
  }, [])

  useEffect(() => {
    _getEachEventElement((eventElement) => {
      eventElement.addEventListener('focus', handleChange)
      eventElement.addEventListener('blur', handleChange)
    })

    return () => {
      _getEachEventElement((eventElement) => {
        eventElement.removeEventListener('focus', handleChange)
        eventElement.removeEventListener('blur', handleChange)
      })
    }
  }, [handleChange])

  return isActive
}
