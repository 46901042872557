import { useMedia as useMediaHook } from 'react-use'

import { screens } from '@config/screens'

type Breakpoint = keyof typeof screens

/**
 * Tracks state of a predefined CSS media query
 *
 * @param breakpoint - The breakpoint reference
 */
export function useMedia(breakpoint: Breakpoint): boolean {
  return useMediaHook(`(min-width: ${screens[breakpoint]})`)
}
