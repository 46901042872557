import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { logout, setToken, useAuthState } from '@features/auth/auth.slice'

import { useAppDispatch } from '@store/hooks'

import { config as cypressConfig } from '@config/cypress'

export const useAuth = (): {
  isAuthenticated: boolean
  isLoading: boolean
  token: string | null
} => {
  const dispatch = useAppDispatch()
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { token } = useAuthState()

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (window.Cypress) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cypressToken = JSON.parse(localStorage.getItem(cypressConfig.tokenStorageKey)!)

      if (cypressToken) {
        dispatch(setToken(cypressToken))
      } else {
        dispatch(logout())
      }
    }, [dispatch])
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      ;(async () => {
        try {
          const token = await getAccessTokenSilently()
          // Improvement to login with token feature - user is logged out after page reload
          // Solution: if token is true but isAuthenticated is false then login by "/user/login"

          if (token) {
            dispatch(setToken(token))
          }
        } catch (error) {
          console.error(error)
          dispatch(logout())
        }
      })()
    }, [dispatch, getAccessTokenSilently, isAuthenticated])
  }

  return { isAuthenticated, isLoading, token }
}
