import React from 'react'

import { Spinner } from '@adapt-agency/gsv-ui-react/dist/components/Spinner'

export const FullPageLoader = () => {
  return (
    <div className="flex flex-1 items-center justify-center text-green">
      <Spinner />
    </div>
  )
}
