import React, { Suspense, lazy } from 'react'

import '@adapt-agency/gsv-ui-react/dist/utils/chartsConfig'

import { useAuth } from '@hooks/index'
import { useHtmlLang } from '@hooks/useHtmlLang'
import { useScrollToTop } from '@hooks/useScrollToTop'

import { FullPageLoader } from '@components/ui'

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'))
const Notifications = lazy(() => import('./Notifications'))

const App = () => {
  const { isLoading, token } = useAuth()

  useScrollToTop() // scroll to top on pathname (page) change
  useHtmlLang() // handles setting the html lang attribute and update dayjs locale

  return (
    <div className="flex min-h-screen flex-col">
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <Suspense fallback={<FullPageLoader />}>
          <Notifications />

          {/*
            If the token exists, that means the user is authenticated.
            Otherwise show the guest view.
          */}
          {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </Suspense>
      )}
    </div>
  )
}

export default App
