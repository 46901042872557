import { Endpoint, dashboardApi, tags } from '@services/dashboard'
import type * as ApiType from '@services/dashboard/types'
import { createSearchParams } from '@services/util'

export const projectsApi = dashboardApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getProjects: build.query<ApiType.GetProjectsResponse, ApiType.GetProjectsParams | void>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.Projects}?${params.toString()}`
        }

        return Endpoint.Projects
      },
      providesTags: (result) => {
        return tags('Projects', result?.data?.map((project) => project.id))
      },
    }),

    getProject: build.query<ApiType.GetProjectResponse, ApiType.GetProjectParams>({
      query: (result) => ({
        url: `${Endpoint.Projects}/${result.id}`,
      }),
      providesTags: (result) => tags('Projects', result?.data?.id),
    }),

    updateProject: build.mutation<
      ApiType.UpdateProjectResponse,
      ApiType.UpdateProjectRequest & ApiType.UpdateProjectParams
    >({
      query: ({ id, ...patch }) => ({
        url: `${Endpoint.Projects}/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result) => [
        ...tags('Projects', result?.data?.id),
        ...tags('UsageProjects'),
      ],
    }),

    createProject: build.mutation<
      ApiType.CreateProjectResponse,
      ApiType.CreateProjectRequest | void
    >({
      query: (body) => ({
        url: Endpoint.Projects,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [...tags('Projects'), ...tags('UsageProjects')],
    }),

    deleteProject: build.mutation<ApiType.DeleteProjectResponse, ApiType.DeleteProjectParams>({
      query: (result) => ({
        url: `${Endpoint.Projects}/${result.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [...tags('Projects'), ...tags('UsageProjects')],
    }),
  }),
})

export const {
  usePrefetch: usePrefetchProjects,
  useGetProjectQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectsApi
