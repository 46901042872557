import { Endpoint, dashboardApi, tags } from '@services/dashboard'
import type * as ApiType from '@services/dashboard/types'
import { createSearchParams } from '@services/util'

export const rentalAgreementsApi = dashboardApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getRentalAgreements: build.query<ApiType.GetRentalAgreementsResponse, ApiType.GetRentalAgreementsParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.RentalAgreements}?${params.toString()}`
        }

        return Endpoint.RentalAgreements
      },
      providesTags: (result) =>
        tags(
          'RentalAgreements',
          result?.data?.map((rentalAgreement) => rentalAgreement.id)
        ),
    }),

    getRentalAgreement: build.query<ApiType.GetRentalAgreementResponse, ApiType.GetRentalAgreementParams>({
      query: ({ id, lineId, ...queryParams }) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.RentalAgreements}/${id}/${lineId}?${params.toString()}`
        }

        return `${Endpoint.RentalAgreements}/${id}/${lineId}`
      },
      providesTags: (result) => tags('RentalAgreements', result?.data?.id),
    }),

    getRentalAgreementStatistics: build.query<
      ApiType.GetRentalAgreementStatisticsResponse,
      ApiType.GetRentalAgreementStatisticsParams
    >({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.RentalAgreements}/statistics?${params.toString()}`
        }

        return `${Endpoint.RentalAgreements}/statistics`
      },
      providesTags: tags('Statistics'),
    }),

    rentalAgreementReturn: build.mutation<void, ApiType.RentalAgreementReturnParams & ApiType.RentalAgreementReturnRequest>({
      query: ({ id, lineId, ...body }) => ({
        url: `${Endpoint.RentalAgreements}/${id}/${lineId}/return`,
        method: 'POST',
        body,
      }),
      invalidatesTags: tags('RentalAgreements'),
    }),

    rentalAgreementUpdate: build.mutation<
      ApiType.RentalAgreementUpdateResponse | void,
      ApiType.RentalAgreementUpdateParams & ApiType.RentalAgreementUpdateRequest
    >({
      query: ({ id, lineId, ...body }) => ({
        url: `${Endpoint.RentalAgreements}/${id}/${lineId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: tags('RentalAgreements'),
    }),
  }),
})

export const {
  usePrefetch: usePrefetchRentalAgreements,
  useGetRentalAgreementsQuery,
  useLazyGetRentalAgreementsQuery,
  useGetRentalAgreementQuery,
  useGetRentalAgreementStatisticsQuery,
  useRentalAgreementReturnMutation,
  useRentalAgreementUpdateMutation,
} = rentalAgreementsApi
