import React, { ReactNode } from 'react'

import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

import { env } from '@config/index'

interface Props {
  children: ReactNode
}

export const Auth0ProviderWithHistory = ({ children }: Props) => {
  const navigate = useNavigate()
  const audience = env.AUTH0_AUDIENCE
  const clientId = env.AUTH0_CLIENT_ID
  const domain = env.AUTH0_DOMAIN

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  const providerConfig: Auth0ProviderOptions = {
    clientId,
    domain,
    onRedirectCallback,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      audience,
      redirect_uri: window.location.origin,
      scope: 'create:booking read:prices return:booking',
    },
  }

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>
}
