import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

import { prepareTagTypes } from '@services/util'

import type { RootState } from '@store/index'

import { env } from '@config/index'

export enum Endpoint {
  CO2 = '/co2',
  Projects = '/projects',
  Searches = '/searches',
  RentalAgreements = '/rental-agreements',
  RentalAgreementsStatistics = '/rental-agreements/statistics',
  Invoices = '/invoices',
  CaseNumbers = '/lists/case-numbers',
  RentalAgreementNumbers = '/lists/rental-agreement-numbers',
  Economy = '/economy',
  UserManagement = '/users',
  Companies = '/companies',
  CompaniesSimple = '/companies_simple',
  UsageCo2Data = '/usage/overview',
  UsageProjects = '/usage/projects',
  UsageProjectsSimple = '/usage/projects/simple',
  UsageMaterials = '/usage/equipment',
  UsageDownload = '/usage/download',
  UsageCo2Budget = '/usage/co2budget',
  UsageIdleTime = '/usage/idletime',
  UsageUtilization = '/usage/utilization',
}

const baseQuery = fetchBaseQuery({
  baseUrl: env.DASHBOARD_SERVICE_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/json')
    const token = (getState() as RootState).auth.token

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    return headers
  },
})

export const tagTypes = [
  'CO2',
  'Projects',
  'Statistics',
  'Searches',
  'RentalAgreements',
  'Economy',
  'UserManagement',
  'Companies',
  'UsageCo2Data',
  'UsageProjects',
  'UsageProjectsSimple',
  'UsageMaterials',
  'UsageDownload',
  'UsageCo2Budget',
  'UsageIdleTime',
  'UsageUtilization',
] as const

export const tags = prepareTagTypes<typeof tagTypes>()

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  refetchOnReconnect: true,
  baseQuery: retry(baseQuery, { maxRetries: 2 }),
  tagTypes,
  endpoints: () => ({}),
})
