import { ReactNode } from 'react'

import { motion } from 'framer-motion'

import { routeChangeDuration } from '@config'

export const RouteTransition = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      key="route-transition"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: routeChangeDuration / 1000 }}
      onAnimationStart={() => document.body.classList.add('overflow-hidden')}
      onAnimationComplete={() => document.body.classList.remove('overflow-hidden')}
      tabIndex={-1}
    >
      {children}
    </motion.div>
  )
}
