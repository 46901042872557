import { Endpoint, dashboardApi, tags } from '@services/dashboard'
import type * as ApiType from '@services/dashboard/types'
import { createSearchParams } from '@services/util'

export const usageApi = dashboardApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getUsageCo2Data: build.query<ApiType.GetUsageCo2DataResponse, ApiType.GetUsageCo2DataParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UsageCo2Data}?${params.toString()}`
        }

        return Endpoint.UsageCo2Data
      },
      providesTags: () => {
        return tags('UsageCo2Data')
      },
    }),
    getUsageMaterials: build.query<ApiType.GetUsageMaterialsResponse, ApiType.GetUsageMaterialsParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UsageMaterials}?${params.toString()}`
        }

        return Endpoint.UsageMaterials
      },
      providesTags: () => {
        return tags('UsageMaterials')
      },
    }),
    getUsageProjects: build.query<ApiType.GetUsageProjectsResponse, ApiType.GetUsageProjectsParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UsageProjects}?${params.toString()}`
        }

        return Endpoint.UsageProjects
      },
      providesTags: (result) => {
        return tags(
          'UsageProjects',
          result?.data?.map((project) => project.id || '')
        )
      },
    }),
    getUsageProjectsSimple: build.query<ApiType.GetUsageProjectsSimpleResponse, ApiType.GetUsageProjectsSimpleParams | void>({
      query: () => {
        return Endpoint.UsageProjectsSimple
      },
      providesTags: (result) => {
        return tags(
          'UsageProjectsSimple',
          result?.data?.map((project) => project.id || '')
        )
      },
    }),
    getUsageCo2Budget: build.query<ApiType.GetUsageCo2BudgetResponse, ApiType.GetUsageCo2BudgetParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UsageCo2Budget}?${params.toString()}`
        }

        return Endpoint.UsageCo2Budget
      },
      providesTags: () => {
        return tags('UsageCo2Budget')
      },
    }),
    getUsageIdleTime: build.query<ApiType.GetUsageIdleTimeResponse, ApiType.GetUsageIdleTimeParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UsageIdleTime}?${params.toString()}`
        }

        return Endpoint.UsageIdleTime
      },
      providesTags: () => {
        return tags('UsageIdleTime')
      },
    }),
    getUsageUtilization: build.query<ApiType.GetUsageUtilizationResponse, ApiType.GetUsageUtilizationParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UsageUtilization}?${params.toString()}`
        }

        return Endpoint.UsageUtilization
      },
      providesTags: () => {
        return tags('UsageUtilization')
      },
    }),
  }),
})

export const {
  usePrefetch: usePrefetchUsageProjects,
  usePrefetch: usePrefetchUsageCo2Data,
  usePrefetch: usePrefetchUsageMaterials,
  useGetUsageMaterialsQuery,
  useGetUsageCo2DataQuery,
  useGetUsageProjectsQuery,
  useGetUsageProjectsSimpleQuery,
  useGetUsageCo2BudgetQuery,
  useGetUsageIdleTimeQuery,
  useGetUsageUtilizationQuery,
} = usageApi
