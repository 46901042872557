import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

import type * as ApiType from '@services/user/types'
import { prepareTagTypes } from '@services/util'

import { RootState } from '@store/index'

import { env } from '@config/index'

export enum Endpoint {
  User = '/users/auth0',
}

const baseQuery = fetchBaseQuery({
  baseUrl: env.USER_SERVICE_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/json')
    const token = (getState() as RootState).auth.token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
})

const tagTypes = ['User'] as const

const tags = prepareTagTypes<typeof tagTypes>()

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: retry(baseQuery, { maxRetries: 2 }),
  tagTypes,
  endpoints: (build) => ({
    getUser: build.query<ApiType.GetUserResponse, void>({
      query: () => Endpoint.User,
      providesTags: tags('User'),
    }),
  }),
})

export const { useGetUserQuery, usePrefetch } = userApi
