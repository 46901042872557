import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist'
import { dashboardApi } from 'services/dashboard'
import { userApi } from 'services/user'

import { usageApi } from '@services/usage'

import { env } from '@config/index'

import persistedReducer from './rootReducer'

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(dashboardApi.middleware, userApi.middleware, usageApi.middleware),
})

if (env.NODE_ENV !== 'production' && import.meta.hot) {
  import.meta.hot.accept('./rootReducer', () => store.replaceReducer(persistedReducer))
}

const persistor = persistStore(store)

// Required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export { store, persistor }
