import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { z } from 'zod'

import { userManagementApi } from '@services/dashboard/entities/user-management/user-management.api'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

const searchSchema = z.object({
  searchQuery: z.string().optional(),
  permissions: z.string().optional().nullable(),
})

export type SearchValues = z.infer<typeof searchSchema>

interface State {
  currentPage: number
  lastPage?: number
  perPage: number
  search: SearchValues
}

const initialState: State = {
  currentPage: 1,
  lastPage: undefined,
  perPage: 10,
  search: {},
}

const userManagementSlice = createSlice({
  name: 'user-management',
  initialState,
  reducers: {
    incrementPage: (state) => {
      state.currentPage += 1
    },

    decrementPage: (state) => {
      state.currentPage -= 1
    },

    /**
     * Set the current page on the overview.
     */
    setPage: (state, { payload }: PayloadAction<State['currentPage']>) => {
      state.currentPage = payload
    },

    /**
     * Set the per page value on the overview.
     */
    setPerPage: (state, { payload }: PayloadAction<State['perPage']>) => {
      state.perPage = payload
      state.currentPage = 1
    },

    setSearch: (state, { payload }: PayloadAction<SearchValues>) => {
      state.search = merge(state.search, payload)
      state.currentPage = initialState.currentPage
    },
  },

  extraReducers: (builder) => {
    builder
      /**
       * Add lastPage meta to state when the {@link getUsers} query fulfills
       */
      .addMatcher(userManagementApi.endpoints.getUsers.matchFulfilled, (state, { payload }) => {
        state.lastPage = payload.meta?.last_page ?? 1
      })
  },
})

export const { incrementPage, decrementPage, setSearch, setPage, setPerPage } =
  userManagementSlice.actions

/**
 * Hook for selecting the UserManagement state
 * @returns The UserManagement state
 */
export const useUserManagementState = (): State => {
  return useAppSelector((state: RootState) => state.userManagement)
}

export default userManagementSlice.reducer
