import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import dayjs from '@lib/dayjs'

import { Language } from '@typings'

/**
 * Hook to set the html and dayjs lang.
 */
export function useHtmlLang() {
  const { i18n } = useTranslation()
  const htmlLang = i18n.language === Language.Danish ? 'da' : 'en'

  useEffect(() => {
    // Set the HTML language
    document.documentElement.lang = htmlLang

    /**
     * Set the Day.js locale
     *
     * @see https://day.js.org/docs/en/i18n/loading-into-browser
     */
    dayjs.locale(htmlLang)
  }, [htmlLang])
}
