import { useEffect } from 'react'

import { useBackground } from '@hooks'

import { routeChangeDuration } from '@config'

/**
 * Scroll to top whenever the route pathname changes.
 *
 * Uses `useBackground` so we don't scroll to top when a dialog route is open.
 */
export function useScrollToTop() {
  const { pathname } = useBackground()

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.scrollTo({ top: 0 })
    }, routeChangeDuration)

    return () => {
      clearTimeout(timeout)
    }
  }, [pathname])
}
