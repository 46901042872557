import React from 'react'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { AccessibleIcon } from '@adapt-agency/gsv-ui-react/dist/components/AccessibleIcon'
import { DKFlagIcon } from '@adapt-agency/gsv-ui-react/dist/icons/flags/DKFlagIcon'
import { UKFlagIcon } from '@adapt-agency/gsv-ui-react/dist/icons/flags/UKFlagIcon'

import { Language as LanguageEnum } from '@typings'

type Language = {
  Icon: any
  label: string
  lng: LanguageEnum
}

const languages: readonly Language[] = [
  { label: 'danish', Icon: DKFlagIcon, lng: LanguageEnum.Danish },
  { label: 'english', Icon: UKFlagIcon, lng: LanguageEnum.English },
]

type Props = {
  className?: string
}

export const LanguageSwitcher = ({ className }: Props) => {
  const { t, i18n } = useTranslation()

  const rootClassName = clsx('flex items-center space-x-3', className)

  const handleChangeLanguage = async (lng: Language['lng']) => {
    try {
      await i18n.changeLanguage(lng)
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <div className={rootClassName}>
      {languages.map(({ label, Icon, lng }) => (
        <button key={label} className="w-6" onClick={() => handleChangeLanguage(lng)}>
          <AccessibleIcon label={t(label)}>
            <Icon width={24} />
          </AccessibleIcon>
        </button>
      ))}
    </div>
  )
}
