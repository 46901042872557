import { Location, useLocation } from 'react-router-dom'

/**
 * Gets the `background` from location state or the current location
 *
 * The `background` is a Location that is used to prevent page transitions
 * being run when a dialog is open.
 * We use this because the dialogs are registered as routes and the page
 * transitions happens when the route pathname changes.
 */
export function useBackground() {
  const location = useLocation()
  const state = (location.state as { background?: Location } | null) || {}
  const { background } = state

  return background ?? location
}
