import { Endpoint, dashboardApi } from '@services/dashboard'
import type * as ApiType from '@services/dashboard/types'
import { createSearchParams } from '@services/util'

export const invoicesApi = dashboardApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getInvoices: build.query<ApiType.GetInvoicesResponse, ApiType.GetInvoicesParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.Invoices}?${params.toString()}`
        }

        return Endpoint.Invoices
      },
    }),
  }),
})

export const { usePrefetch: usePrefetchInvoices, useGetInvoicesQuery } = invoicesApi
