import { createSlice } from '@reduxjs/toolkit'

import { logout } from '@features/auth/auth.slice'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

interface UIState {
  navIsOpen: boolean
  sidebarIsCollapsed: boolean
}

const initialState: UIState = {
  navIsOpen: false,
  sidebarIsCollapsed: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleNav: (state) => {
      state.navIsOpen = !state.navIsOpen
    },
    openNav: (state) => {
      state.navIsOpen = true
    },
    closeNav: (state) => {
      state.navIsOpen = false
    },
    toggleCollapseSidebar: (state) => {
      state.sidebarIsCollapsed = !state.sidebarIsCollapsed
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState)
  },
})

// Selectors
export const selectSidebarIsCollapsed = (state: RootState) => state.ui.sidebarIsCollapsed

// Actions
export const { toggleNav, openNav, closeNav, toggleCollapseSidebar } = uiSlice.actions

/**
 * Hook for selecting the UI state
 * @returns The UI state
 */
export const useUIState = (): UIState => useAppSelector((state: RootState) => state.ui)

export default uiSlice.reducer
